import '../style/Tecnologia.css';
import Producto from '../componentes/Producto';

function Tecnologia() {
  return (
    <main className='main-tecnologia'>
      <div className='contenedor-titulo-libros'>
        <h1 className='titulos-libros'>E-Readers</h1>
      </div>
      <div className='contenedor-tecnologia'>
        <Producto
          ruta={'https://amzn.to/4htyBln'}
          imagen={'https://m.media-amazon.com/images/I/51kc5N5DOSL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Nuevo Amazon Kindle (16 GB)'}
          titulo={'Nuevo Amazon Kindle (16 GB), mas detalles...'}
        />
        <Producto
          ruta={'https://amzn.to/3PMJoem'}
          imagen={'https://m.media-amazon.com/images/I/51dBh5Q1OtL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Meebook E-Reader P78 Pro'}
          titulo={'Meebook E-Reader P78 Pro | Pantalla de 7.8" Eink Carta 300PPI. Ver más características...'}
        />
        <Producto
          ruta={'https://amzn.to/4axIBYj'}
          imagen={'https://m.media-amazon.com/images/I/61ZBEwtHpJL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Meebook E-Reader M6'}
          titulo={'Meebook E-Reader M6 | Pantalla 6\' Eink Carta 300PPI. Ver más características...'}
        />
        <Producto
          ruta={'https://amzn.to/42lOtll'}
          imagen={'https://m.media-amazon.com/images/I/61XUdMXS6YL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Bigme inkNote Color'}
          titulo={'Bigme inkNote Color + Lite Ereader 10.3" 4G + 64G Ebook Tablet para Leer y Escribir...'}
        />
        <Producto
          ruta={'https://amzn.to/4jFYtMP'}
          imagen={'https://m.media-amazon.com/images/I/510IfnZspQL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Nuevo Amazon Kindle Scribe'}
          titulo={'Nuevo Amazon Kindle Scribe (64 GB), mas detalles...'}
        />
        <Producto
          ruta={'https://amzn.to/4hr4YRv'}
          imagen={'https://m.media-amazon.com/images/I/61Rl35eaKPL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Woxter E-Book Scriba 195 Blue'}
          titulo={'Woxter E-Book Scriba 195 Blue- Lector de Libros electrónicos 6" (1024x758, E-Ink Pearl Pantalla más Blanca, EPUB, PDF)...'}
          changeClass="producto-img-h" 
        />
        <Producto
          ruta={'https://amzn.to/40v2meu'}
          imagen={'https://m.media-amazon.com/images/I/81sGcEIzKlL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Kobo Libra 2'}
          titulo={'Kobo Libra 2 | eReader | Pantalla 7" táctil Carta EInk antirreflejos ...'}
        />
        <Producto
          ruta={'https://amzn.to/3E7NF9R'}
          imagen={'https://m.media-amazon.com/images/I/81fEqEOgRsL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Kobo Clara 2E'}
          titulo={'Kobo Clara 2E | eReader | Pantalla táctil antirreflejos de 6” y Alta definición ...'}
        />
      </div>
    </main>

  );
};

export default Tecnologia;