import '../style/Infantiles.css';
import Producto from '../componentes/Producto';

function Infantiles() {
  return (
    <main className='main-infantiles'>
      <div className='contenedor-titulo-libros'>
        <h1 className='titulos-libros'>Destacados</h1>
      </div>
      <div className='contenedor-libros-infantiles'>
        <Producto
          ruta={'https://amzn.to/40n8p4H'}
          imagen={'https://m.media-amazon.com/images/I/91R1AixEiLL._SY522_.jpg'}
          altDescripcion={'Foto Libro Harry Potter y la piedra filosofal'}
          titulo={'Harry Potter y la piedra filosofal'}
        />
        <Producto
          ruta={'https://amzn.to/4jkAkLp'}
          imagen={'https://m.media-amazon.com/images/I/81j4JQl-J7L._SY522_.jpg'}
          altDescripcion={'Imágen libro Aprende a leer con Las Fantasticotas 1'}
          titulo={'Aprende a leer con Las Fantasticotas 1'}
        />
        <Producto
          ruta={'https://amzn.to/3DYEZCG'}
          imagen={'https://m.media-amazon.com/images/I/61pocWFztNL._SY522_.jpg'}
          altDescripcion={'Foto Libro Tengo un volcán'}
          titulo={'Tengo un volcán'}
        />
        <Producto
          ruta={'https://amzn.to/4gYgKmD'}
          imagen={'https://m.media-amazon.com/images/I/31gFWUWv5YL.jpg'}
          altDescripcion={'Foto Libro Tu cuerpo es tuyo'}
          titulo={'Tu cuerpo es tuyo'}
        />
        <Producto
          ruta={'https://amzn.to/4hjnpaI'}
          imagen={'https://m.media-amazon.com/images/I/71axBX6p3kL._SY522_.jpg'}
          altDescripcion={'Foto Libro Mi mamá es la mejor'}
          titulo={'Bluey. Un cuento - Mi mamá es la mejor'}
        />
        <Producto
          ruta={'https://amzn.to/42ENIUX'}
          imagen={'https://m.media-amazon.com/images/I/71e8W72ngEL._SY522_.jpg'}
          altDescripcion={'Foto Libro Cuentos Clásicos'}
          titulo={'Cuentos Clásicos (con pictogramas)'}
        />
        <Producto
          ruta={'https://amzn.to/4g63R8R'}
          imagen={'https://m.media-amazon.com/images/I/71cNmMaCOyL._SY522_.jpg'}
          altDescripcion={'Foto Libro El hilo invisible'}
          titulo={'El hilo invisible (Pequeñas manitas)'}
        />
        <Producto
          ruta={'https://amzn.to/3DZnSjU'}
          imagen={'https://m.media-amazon.com/images/I/813s46i96BL._SY522_.jpg'}
          altDescripcion={'Foto Libro El Rulas y la Copa Legendaria'}
          titulo={'El Rulas 2. El Rulas y la Copa Legendaria'}
        />
        <Producto
          ruta={'https://amzn.to/3C6Z9Ka'}
          imagen={'https://m.media-amazon.com/images/I/81jqyNn5jBL._SY522_.jpg'}
          altDescripcion={'Foto Libro Nos tratamos bien'}
          titulo={'Nos tratamos bien "cuento"'}
        />
        <Producto
          ruta={'https://amzn.to/40ncd6a'}
          imagen={'https://m.media-amazon.com/images/I/71grlLJ5byL._SY522_.jpg'}
          altDescripcion={'Foto Libro Adiós, enfado. ¡Hola, calma!'}
          titulo={'Adiós, enfado. ¡Hola, calma!'}
        />  
        <Producto
          ruta={'https://amzn.to/4fZTUK0'}
          imagen={'https://m.media-amazon.com/images/I/61-b+HaddPL._SY522_.jpg'}
          altDescripcion={'Foto Libro casita pequeña y su querido perro'}
          titulo={'CASITA PEQUEÑA Y SU QUERIDO PERRO: Amor y empatía'}
        />
        <Producto
          ruta={'https://amzn.to/3WuKPlt'}
          imagen={'https://m.media-amazon.com/images/I/714Hvb52n-L._SY522_.jpg'}
          altDescripcion={'Foto Libro El Principito'}
          titulo={'El Principito (Biblioteca Saint-Exupéry) Tapa blanda'}
        />     
        <Producto
          ruta={'https://amzn.to/40H92HC'}
          imagen={'https://m.media-amazon.com/images/I/819OEEwR9SL._AC_SX679_.jpg'}
          altDescripcion={'Foto Libro Bebe'}
          titulo={'hahaland Libro Bebe Juguetes Bebes 0 3 6 12 Meses, Libro de Tela Blandos'}
        />   
        <Producto
          ruta={'https://amzn.to/40pNwFZ'}
          imagen={'https://m.media-amazon.com/images/I/713N3hYVaBL._AC_SX679_.jpg'}
          altDescripcion={'Foto Libro Bebe Juguetes'}
          titulo={'Libros Bebe Juguetes Bebes 0 3 6 12 Meses, 2PCS Libros Blandos de Tela'}
        /> 
        <Producto
          ruta={'https://amzn.to/3POTuLH'}
          imagen={'https://m.media-amazon.com/images/I/71HssRrP4gL._SY522_.jpg'}
          altDescripcion={'Foto Libro El bebé toca y descubre'}
          titulo={'El bebé toca y descubre (Mi primer toca, toca)'}
        />    
        <Producto
          ruta={'https://amzn.to/4am35mR'}
          imagen={'https://m.media-amazon.com/images/I/713lAyolk1L._SY522_.jpg'}
          altDescripcion={'Foto Libro Todos a dormir'}
          titulo={'Cuentos blanditos - ¡Todos a dormir!: Cuentos sensoriales con texturas para bebés de 0 a 3 años'}
        />           

      </div>

    </main>
  );
};

export default Infantiles;