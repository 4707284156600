import '../style/Adultos.css';
import Libro from '../componentes/Libro';

function Adultos() {
  return (
    <main className='main-adultos'>
      <div className='contenedor-titulo-libros'>
        <h1 className='titulos-libros'>Destacados</h1>
      </div>
      <div className='contenedor-libros-adultos'>
        <Libro
          ruta={'https://amzn.to/4hm7WH4'}
          imagen={'https://m.media-amazon.com/images/I/81kY0YQcIbL._SY522_.jpg'}
          altDescripcion={'Foto Libro BAJO UN CIELO ESCOCÉS'}
          titulo={'BAJO UN CIELO ESCOCÉS'}
        />  
        <Libro
          ruta={'https://amzn.to/40DYVmV'}
          imagen={'https://m.media-amazon.com/images/I/61AQhnVmD5L._SY522_.jpg'}
          altDescripcion={'Foto Libro ¿A qué estás esperando?'}
          titulo={'¿A qué estás esperando?'}
        />   
        <Libro
          ruta={'https://amzn.to/3DZQhXb'}
          imagen={'https://m.media-amazon.com/images/I/71M1Lou7DtL._SY522_.jpg'}
          altDescripcion={'Foto Libro Te espero en el fin del mundo'}
          titulo={'Te espero en el fin del mundo'}
        />   
        <Libro
          ruta={'https://amzn.to/42iCR2v'}
          imagen={'https://m.media-amazon.com/images/I/71m1QKs2AdL._SY522_.jpg'}
          altDescripcion={'Foto Libro Todo fue culpa de la nieve'}
          titulo={'Todo fue culpa de la nieve'}
        />   
        <Libro
          ruta={'https://amzn.to/40Dx46x'}
          imagen={'https://m.media-amazon.com/images/I/81gljyRLiOL._SY522_.jpg'}
          altDescripcion={'Foto Libro Las hijas de la criada'}
          titulo={'Las hijas de la criada'}
        />   
        <Libro
          ruta={'https://amzn.to/4h4Z60G'}
          imagen={'https://m.media-amazon.com/images/I/71IcU2cTf2L._SY522_.jpg'}
          altDescripcion={'Foto Libro TÉCNICAS PARA APROBAR UNA OPOSICIÓN'}
          titulo={'Técnicas para Aprobar una Oposición'}
        />   
        <Libro
          ruta={'https://amzn.to/4g6kYak'}
          imagen={'https://m.media-amazon.com/images/I/71KpYkSWqvL._SY522_.jpg'}
          altDescripcion={'Foto Libro de Colorear para Adultos'}
          titulo={'Libro de Colorear para Adultos'}
        />   
        <Libro
          ruta={'https://amzn.to/42kevW6'}
          imagen={'https://m.media-amazon.com/images/I/91+SNVKv+OL._SY522_.jpg'}
          altDescripcion={'Foto Libro Plantas medicinales'}
          titulo={'Plantas medicinales'}
        />   
        <Libro
          ruta={'https://amzn.to/4ge4rS8'}
          imagen={'https://m.media-amazon.com/images/I/91Cf1wuZw-L._SY522_.jpg'}
          altDescripcion={'Foto Libro Ágilmente: ejercicios mentales'}
          titulo={'Ágilmente: ejercicios mentales'}
        />   
        <Libro
          ruta={'https://amzn.to/40rm3Ed'}
          imagen={'https://m.media-amazon.com/images/I/717CVfJKllL._SY522_.jpg'}
          altDescripcion={'Foto Libro Método Montessori'}
          titulo={'Método Montessori'}
        />    
      </div>

    </main>
  );
};

export default Adultos;