import '../style/LibrosBS.css';
import Producto from '../componentes/Producto';


function LibrosBS() {
  return (
    <main className="main-librosbs">
      <div className='contenedor-titulo-libros'>
        <h1 className='titulos-libros'>Nuestros Títulos</h1>
      </div>
      <div className="contenedor-librosbs">  
        <Producto          
          ruta={'https://amzn.to/3ZZTEpD'}
          imagen={'https://m.media-amazon.com/images/I/71o3yNQnuZL._SY522_.jpg'}
          altDescripcion={'Imágen libro Cumbres Borrascosas II Amores del más allá'}
          titulo={'Cumbres Borrascosas II, Amores del más allá'}          
        />             
        <Producto          
          ruta={'https://amzn.to/3ZCHXUg'}
          imagen={'https://m.media-amazon.com/images/I/71KkOD3xrYL._SY522_.jpg'}
          altDescripcion={'Imágen Mis Cuentos Navideños'}
          titulo={'Mis Cuentos Navideños'}          
        />  
        <Producto          
          ruta={'https://amzn.to/3P2aYEj'}
          imagen={'https://m.media-amazon.com/images/I/714TYmCv5CL._SY522_.jpg'}
          altDescripcion={'Imágen libro Cuentos de La Granja: La Gallina Pepi'}
          titulo={'Cuentos de La Granja: La Gallina Pepi'}          
        />
        <Producto          
          ruta={'https://amzn.to/3ZNEUch'}
          imagen={'https://m.media-amazon.com/images/I/71ZUdD0V85L._SY522_.jpg'}
          altDescripcion={'Imágen libro Cuentos para dormir 1'}
          titulo={'Cuentos para Dormir 1'}          
        />
        <Producto          
          ruta={'https://amzn.to/49HSFhb'}
          imagen={'https://m.media-amazon.com/images/I/71u2Ukn0r6L._SY522_.jpg'}
          altDescripcion={'Imágen libro Cuentos para dormir 2'}
          titulo={'Cuentos para Dormir 2: Enfrentando los miedos'}          
        />
        <Producto          
          ruta={'https://amzn.to/3BtqTs6'}
          imagen={'https://m.media-amazon.com/images/I/71DuuvlhGdL._SY522_.jpg'}
          altDescripcion={'Imágen libro Cuentos para dormir 3'}
          titulo={'Cuentos para dormir 3: Aventuras Fantásticas'}          
        />
        <Producto          
          ruta={'https://amzn.to/41JQpnw'}
          imagen={'https://m.media-amazon.com/images/I/71hxedZL43L._SY522_.jpg'}
          altDescripcion={'Imágen libro Mi Madre, mi Arcoíris'}
          titulo={'Mi Madre, mi Arcoíris'}          
        />
        <Producto          
          ruta={'https://publish.mibestseller.es/books/372769'}
          imagen={'https://publish.mibestseller.es/site/bookWidgets/showCoverImage/id/366290'}
          altDescripcion={'Imágen libro Poemas para mi Madre'}
          titulo={'Poemas para mi Madre'}          
        />
        <Producto          
          ruta={'https://amzn.to/3Btrnyq'}
          imagen={'https://m.media-amazon.com/images/I/81Ylomw3PUL._SY522_.jpg'}
          altDescripcion={'Imágen libro El gato negro'}
          titulo={'El Gato Negro (Ilustrado)'}          
        />
        <Producto         
          ruta={'https://publish.mibestseller.es/books/376860'}
          imagen={'https://publish.mibestseller.es/site/bookWidgets/showCoverImage/id/371141'}
          altDescripcion={'Imágen libro La Verdad Sobre Caperucita'}
          titulo={'La Verdad Sobre Caperucita'}          
        />
        <Producto         
          ruta={'https://publish.mibestseller.es/books/303858'}
          imagen={'https://publish.mibestseller.es/site/bookWidgets/showCoverImage/id/303858'}
          altDescripcion={'Imágen libro La Durmiente no es'}
          titulo={'LA DURMIENTE NO ES'}          
        />
        <Producto         
          ruta={'https://publish.mibestseller.es/books/301039'}
          imagen={'https://publish.mibestseller.es/site/bookWidgets/showCoverImage/id/301039'}
          altDescripcion={'Imágen libro Barba Azul ¿El Despiadado?'}
          titulo={'Barba Azul ¿El Despiadado?'}          
        />
        <Producto         
          ruta={'https://amzn.to/4frXlIY'}
          imagen={'https://m.media-amazon.com/images/I/81bXNXFmryL._SY522_.jpg'}
          altDescripcion={'Imágen libro La Divina Comedia'}
          titulo={'La Divina Comedia'}          
        />
        <Producto          
          ruta={'https://amzn.to/3ZEwofs'}
          imagen={'https://m.media-amazon.com/images/I/71Tcq8A-8SL._SY522_.jpg'}
          altDescripcion={'Imágen libro El Matadero'}
          titulo={'El Matadero (Esteban Echeverría)'}          
        />

      </div>
    </main>
  );
};

export default LibrosBS;