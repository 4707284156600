import '../style/Juegos.css';
import Producto from '../componentes/Producto';

function Juegos() {
  return (
    <main className='main-juegos'>
      <div className='contenedor-titulo-libros'>
        <h1 className='titulos-libros'>Destacados Juegos de Mesa</h1>
      </div>
      <div className='contenedor-juegos'>
        <Producto
          ruta={'https://amzn.to/4au54Wj'}
          imagen={'https://m.media-amazon.com/images/I/812noL4zLgL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juego de Stonemaier Games'}
          titulo={'Stonemaier Games: Wingspan,Expansión Asia, juego de mesa de estrategia, 14 años en adelante.'}
        />
        <Producto
          ruta={'https://amzn.to/4g39eFK'}
          imagen={'https://m.media-amazon.com/images/I/91tN15xlQcL._AC_SY879_.jpg'}
          altDescripcion={'Imagen Pegasus Spiele'}
          titulo={'Pegasus Spiele- Juegos de Mesa'}
        />
        <Producto
          ruta={'https://amzn.to/4geN17I'}
          imagen={'https://m.media-amazon.com/images/I/71K8gdgfFmL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Greater Than Games Sentinels of The Multiverse'}
          titulo={'Greater Than Games Sentinels of The Multiverse: Rook City Renegades | Expansión de juego de mesa de estrategia cooperativa'}
        />
        <Producto
          ruta={'https://amzn.to/4jutCTk'}
          imagen={'https://m.media-amazon.com/images/I/71xse8oLKQL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juego de mesa Coca Cola-Opoly'}
          titulo={'Juego de mesa Coca Cola-Opoly (mpc)'}
          changeClass='producto-img-h'  
        />
        <Producto
          ruta={'https://amzn.to/3PJ8w5M'}
          imagen={'https://m.media-amazon.com/images/I/81aAVNUE2JL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juego Devir- Carcassone: Expansión La Abadía y El Alcade'}
          titulo={'Devir- Carcassone: Expansión La Abadía y El Alcade, Juego de Mesa, Juego de Mesa 8 años'}
        />
        <Producto
          ruta={'https://amzn.to/3E71khh'}
          imagen={'https://m.media-amazon.com/images/I/61lh3L1yR2L._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Space Cowboys'}
          titulo={'Space Cowboys | Splendor Marvel | Juego de Mesa de Estrategia para Adultos y Familias | A Partir de 10 Años'}
        />
        <Producto
          ruta={'https://amzn.to/4jpWJac'}
          imagen={'https://m.media-amazon.com/images/I/71KGCi3Zf7L._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juego de mesa RA'}
          titulo={'Juego de mesa RA para 2 a 5 jugadores'}
        />
        <Producto
          ruta={'https://amzn.to/40HEtBN'}
          imagen={'https://m.media-amazon.com/images/I/81TFRNdR6wL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Acquire'}
          titulo={'Renegade Game Studios Adquiere un juego de mesa de estrategia para 2 a 6 jugadores, a partir de 12 años con 2 modos de juego'}
        />
        <div className='contenedor-titulo-libros'>
          <h1 className='titulos-libros'>Juegos y juguetes para niños</h1>
        </div>
        <Producto
          ruta={''}
          imagen={'https://m.media-amazon.com/images/I/71re4XIDUxL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juguetes de playa para niños'}
          titulo={'Juguetes de Playa para niños,Juguete arenero Dinosaurio con Rueda de Agua...'}
        />
        <Producto
          ruta={'https://amzn.to/3CmXBM1'}
          imagen={'https://m.media-amazon.com/images/I/81C9UWPn2iL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Kit de Exploración'}
          titulo={'Frasheng Kit de Exploración para Niños 28 en 1, Juego de Explorador para Niños...'}
        />
        <Producto
          ruta={'https://amzn.to/3WuOnEx'}
          imagen={'https://m.media-amazon.com/images/I/61i7U28f61L._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego ISAKEN Marionetas'}
          titulo={'ISAKEN Marionetas de Mano para niños...'}
          changeClass='producto-img-h' 
        />
        <Producto
          ruta={'https://amzn.to/40JbugY'}
          imagen={'https://m.media-amazon.com/images/I/717NwSTg0NL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego desmontar dinosaurio'}
          titulo={'Desmontar Dinosaurios Juguetes, Bricolaje Construccion Dino'}          
        />
        <Producto
          ruta={'https://amzn.to/3CrYx1C'}
          imagen={'https://m.media-amazon.com/images/I/71qnqGDPUwL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Glop Alien'}
          titulo={'Glop Alien, Juegos de Mesa Niños 8+ Años y Adultos, Divertidos Juegos de Mesa...'}          
        />
        <Producto
          ruta={'https://amzn.to/40KkGBy'}
          imagen={'https://m.media-amazon.com/images/I/71OoHuV7ciL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Glop 3Pizza'}
          titulo={'Glop 3Pizza, Juegos de Mesa para Niños a Partir de 8 años y Adultos, Juego de Cartas...'}          
        />
        <Producto
          ruta={'https://amzn.to/42vTLe2'}
          imagen={'https://m.media-amazon.com/images/I/71d82INK8IL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Mesa Dardos'}
          titulo={'Juego de Dardos de Doble Cara para niños'}          
        />
        <Producto
          ruta={'https://amzn.to/4hqO4SZ'}
          imagen={'https://m.media-amazon.com/images/I/71T-bHqV7HL._AC_SY879_.jpg'}
          altDescripcion={'Imagen juego Simba Verter Agua'}
          titulo={'Simba, Verter Agua y apretando los Anillos al Objetivo, Juego de Viaje...'}          
        />
      </div>
    </main>

  );
};

export default Juegos;