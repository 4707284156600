import '../style/Destacado.css';

function Destacado({ imagen, altImagen, tituloGeneral,tituloLibro, texto1, texto2 }) {
  return (
    <div className='contenedor-principal-destacado'>
      
        <div className='contenedor-destacado-foto'>
          <a href='https://amzn.to/3ZZTEpD'> <img src={imagen} alt={altImagen} /> </a>
        </div>
        <div className='contenedor-destacado-texto'>
          <h1><a href='https://amzn.to/3ZZTEpD'>{tituloGeneral}</a></h1>
          <h2><a href='https://amzn.to/3ZZTEpD'>{tituloLibro}</a></h2>
          <p>{texto1}</p>
          <p>{texto2}<a href="mailto:elbuensaberlibros@gmail.com">elbuensaberlibros@gmail.com</a></p>
        </div>
      
    </div>
  );
};

export default Destacado;